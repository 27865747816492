import React from 'react';
import { Autocomplete as PdcAutocomplete, Box, TextField, InputAdornment } from '@mzc-pdc/ui';
import { AutocompleteProps } from './infrastructures/AutocompleteProps';
import { IconSearch } from '@mzc-cloudplex/icons';

export const Autocomplete = <T, Multiple extends boolean | undefined = false>(
  props: AutocompleteProps<T, Multiple>
) => (
  <PdcAutocomplete
    id='tags-filled'
    freeSolo={false}
    sx={{ minWidth: props.minWidth ?? 350 }}
    options={props.options}
    loading={props.loading}
    disabled={props.disabled}
    filterOptions={props.filterOptions}
    multiple={props.multiple ?? false}
    disableCloseOnSelect={props.multiple ?? false}
    value={props.value}
    onChange={(_event, values) => props.onChange(values)}
    renderTags={props.renderTags}
    inputValue={props.inputValue}
    onInputChange={(_event, value) => props?.onInputChange?.(value)}
    renderInput={(params) => (
      <TextField
        {...params}
        value={props.inputValue}
        variant={'outlined'}
        label={props?.label}
        InputProps={{
          ...params.InputProps,
          // startAdornment 사용시 renderTag 가 작동하지 않는 이슈가 있음.
          ...(props?.showSearchIcon && {
            startAdornment: (
              <InputAdornment position='start'>
                <IconSearch />
              </InputAdornment>
            )
          })
        }}
        inputProps={{
          ...params.inputProps,
          readOnly: props.readonly
        }}
        placeholder={props.placeholder ?? ''}
      />
    )}
    getOptionLabel={props.getOptionLabel}
    isOptionEqualToValue={props.objectComparisonFn ?? undefined}
    renderOption={(listProp, option, { selected }) => (
      <li {...listProp}>
        <Box display={'flex'} gap={1}>
          {props?.renderOption?.(option, selected)}
        </Box>
      </li>
    )}
  />
);
