import React from 'react';
import { Box, TextField, Typography } from '@mzc-pdc/ui';
import InputFieldProps from './infrastructures/InputFieldProps';
import { theme } from '../../theme';

export const NumberWithReferenceInputField = (
  props: InputFieldProps & {
    reference: string;
  }
) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      textarea: { '~ div': { display: `none` } }
    }}
  >
    <TextField
      fullWidth
      sx={{
        '.MuiOutlinedInput-root': {
          borderRadius: '3px 0px 0px 3px '
        }
      }}
      inputProps={{
        style: {
          height: '22px',
          fontSize: '14px',
          padding: '0px 10px',
          margin: '8px 0px 32px 0px'
        }
      }}
      variant='outlined'
      type={'number'}
      disabled={props.disabled ?? false}
      required={props.required ?? false}
      label={props.label ?? ''}
      InputProps={{
        startAdornment: props.startAdornment,
        endAdornment: props.endAdornment
      }}
      value={props.value?.toString()}
      placeholder={props.placeholder ?? ''}
      onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        props.onChange(e.target.value)
      }
      error={props?.errorMessage != null}
      helperText={props?.errorMessage ?? props?.helperText ?? ''}
    />
    <Typography
      sx={{ position: `absolute`, top: `38px`, left: `8px` }}
      color={theme.palette.secondary[400]}
      variant={'body2'}
      fontSize={'11px'}
    >
      {props.reference}
    </Typography>
  </Box>
);
