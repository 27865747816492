import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Avatar,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Popover,
  theme,
  Typography
} from '@mzc-pdc/ui';
import { IconApps, IconOpenInNewSmall, ImageLogoCloudplexDelivery } from '@mzc-cloudplex/icons';
import { User } from '../../../infrastructures/user';
import { useTranslation } from 'react-i18next';

export const Header = ({
  myInfo,
  homeDirection,
  onLogout
}: {
  myInfo: User;
  homeDirection: string;
  onLogout: () => void;
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'header' });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleUserClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const onHandleClickProfile = useCallback(() => {
    window.open(`https://myaccount.megazone.com/info`);
  }, []);

  return (
    <AppBar
      position={'static'}
      sx={{
        zIndex: theme.zIndex.appBar,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.secondary.main,
        boxShadow: `none`,
        borderBottom: `1px solid ${theme.palette.divider}`
      }}
    >
      <Grid container alignItems={'stretch'} height={60}>
        <Grid item>
          <IconButton circled size={'large'} color={'grey'}>
            <IconApps size={20} />
          </IconButton>
        </Grid>
        <Divider flexItem orientation={'vertical'} />
        <Grid item px={2.5} flexGrow={1}>
          <Grid container alignItems={'center'} height={`100%`}>
            <Link to={homeDirection} style={{ color: 'black' }}>
              <ImageLogoCloudplexDelivery width={218} height={28} />
            </Link>
          </Grid>
        </Grid>
        <Grid item display={'flex'} gap={2} alignItems={'center'} pl={2.5} pr={1}>
          <IconButton circled size={'large'} onClick={handleUserClick}>
            <Avatar sx={{ width: 24, height: 24 }} src={myInfo?.profileImageUrl} />
          </IconButton>
        </Grid>
      </Grid>
      <Popover open={anchorEl != null} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <List>
          <ListItem sx={{ flexDirection: `column`, alignItems: `baseline` }}>
            <Typography>{t('account')}</Typography>
            <Grid container alignItems={'center'} flexWrap={'nowrap'} mt={1.5} pb={1} gap={2}>
              <ListItemAvatar>
                <Avatar sx={{ width: 40, height: 40 }} src={myInfo?.profileImageUrl} />
              </ListItemAvatar>
              <ListItemText
                primary={myInfo?.displayName}
                secondary={myInfo?.email}
                primaryTypographyProps={{ fontWeight: 500 }}
                secondaryTypographyProps={{ fontSize: theme.typography.body3.fontSize }}
              />
            </Grid>
          </ListItem>
          <ListItem
            disablePadding
            sx={{ padding: `8px 0`, borderTop: `1px solid ${theme.palette.divider}` }}
          >
            <ListItemButton onClick={onHandleClickProfile}>
              <ListItemText
                primary={
                  <>
                    {t('manageAccount')} <IconOpenInNewSmall size={20} />
                  </>
                }
                primaryTypographyProps={{
                  fontWeight: 500,
                  display: `flex`,
                  alignItems: `center`,
                  gap: 1
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{ padding: `8px 0 0`, borderTop: `1px solid ${theme.palette.divider}` }}
          >
            <ListItemButton onClick={onLogout}>
              <ListItemText primary={t('logout')} primaryTypographyProps={{ fontWeight: 500 }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </AppBar>
  );
};
