import React, { useState } from 'react';
import InputFieldProps from './infrastructures/InputFieldProps';
import { IconButton, InputAdornment, TextField } from '@mzc-pdc/ui';
import { IconVisibility, IconVisibilityOff } from '@mzc-cloudplex/icons';

export const PasswordInputField = (
  props: Omit<InputFieldProps, 'type' | 'endAdornment' | 'required'>
) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) =>
    event.preventDefault();

  return (
    <TextField
      variant='outlined'
      size={'medium'}
      fullWidth
      type={showPassword ? 'text' : 'password'}
      disabled={props.disabled ?? false}
      required={true}
      label={props.label ?? ''}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              circled
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? (
                <IconVisibilityOff size={16} htmlColor={'#83839a'} />
              ) : (
                <IconVisibility size={16} htmlColor={'#83839a'} />
              )}
            </IconButton>
          </InputAdornment>
        )
      }}
      value={props.value}
      placeholder={props.placeholder ?? ''}
      onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        props.onChange(e.target.value)
      }
      error={props?.errorMessage != null}
      helperText={props?.errorMessage}
    />
  );
};
