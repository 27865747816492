import React, { useState } from 'react';

export const useAnchor = () => {
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  return {
    anchor: anchor,
    resetAnchor: () => setAnchor(null),
    handleActionClick: (params: React.MouseEvent<HTMLButtonElement>) =>
      setAnchor(params.currentTarget)
  };
};

export default useAnchor;
