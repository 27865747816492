import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import locales from '@locales/index';

const DEFAULT_LANGUAGE = 'en';

const resources: Record<string, Record<string, object>> = {
  en: locales['en-us'],
  ko: locales['ko-kr']
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: DEFAULT_LANGUAGE,
    lowerCaseLng: true,
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      lookupQuerystring: 'lang',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLang',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
      caches: ['localStorage', 'cookie'],
      excludeCacheFor: ['cimode'],
      cookieMinutes: 10,
      cookieDomain: 'myDomain',
      htmlTag: document.documentElement,
    },
    defaultNS: 'common',
    nsSeparator: '::',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
