import React from 'react';
import { InputAdornment, TextField } from '@mzc-pdc/ui';
import InputFieldProps from './infrastructures/InputFieldProps';

export const InputField = (props: InputFieldProps) => (
  <TextField
    fullWidth
    variant='outlined'
    size={'medium'}
    type={'text'}
    disabled={props.disabled ?? false}
    required={props.required ?? false}
    label={props.label}
    InputProps={{
      startAdornment: props.startAdornment && (
        <InputAdornment position='start'>{props.startAdornment}</InputAdornment>
      ),
      endAdornment: props.endAdornment && (
        <InputAdornment position='end'>{props.endAdornment}</InputAdornment>
      )
    }}
    value={props.value || ''}
    placeholder={props.placeholder ?? ''}
    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      props.onChange(e.target.value)
    }
    error={props?.errorMessage != null}
    helperText={props?.errorMessage}
  />
);
