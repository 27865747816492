import React from 'react';
import { useCookies } from 'react-cookie';
import { Footer as CPFooter } from '@packages/cp-ui';
import { useTranslation } from 'react-i18next';
import { config } from '@config/config';

const Footer = () => {
  const { i18n } = useTranslation('common', { keyPrefix: 'footer' });
  const [_, setCookie] = useCookies();
  return (
    <CPFooter
      onLocaleChange={async (value) => {
        await i18n.changeLanguage(value);
        setCookie('i18next', value, {
          expires: new Date(Date.now() + 60 * 60 * 24 * 1000), // 1 day
          path: '/',
          domain: config.domain
        });
      }}
    />
  );
};

export default Footer;
