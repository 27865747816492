import React from 'react';
import { Box, Button, ClickAwayListener, Popover, Typography } from '../../index';
import { IconArrowDropDown } from '@mzc-cloudplex/icons';
import useAnchor from '../../hook/useAnchor';

export const FilterContainer = ({
  children,
  label,
  searchTag,
  active = false,
  anchorBundle = useAnchor()
}: {
  children: React.ReactNode;
  label: string;
  searchTag: React.ReactNode;
  active?: boolean;
  anchorBundle?: {
    anchor: HTMLButtonElement | null;
    resetAnchor: () => void;
    handleActionClick: (params: React.MouseEvent<HTMLButtonElement>) => void;
  };
}) => {
  const { anchor, resetAnchor, handleActionClick } = anchorBundle;
  return (
    <>
      <Button
        variant={'text'}
        color={'secondary'}
        onClick={handleActionClick}
        endIcon={<IconArrowDropDown color={'action'} />}
        sx={{
          whiteSpace: 'nowrap',
          minWidth: 'max-content',
          borderRadius: 12,
          backgroundColor: active ? '#E9EEFB' : 'transparent'
        }}
      >
        <Box display={'inline-flex'} gap={1} alignItems={'baseline'}>
          <Typography color={active ? 'primary' : 'grey'}>{label}</Typography>
          {searchTag}
        </Box>
      </Button>
      <Popover
        open={!!anchor}
        anchorEl={anchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <ClickAwayListener onClickAway={resetAnchor}>
          <Box p={2}>{children}</Box>
        </ClickAwayListener>
      </Popover>
    </>
  );
};
