import React, { useState } from 'react';
import { Stepper as StyledStepper } from './infrastructures/styled';
import { StepperController } from './infrastructures/StepperController';
import { StepProps } from './infrastructures/StepProps';
import { StepperContext } from './infrastructures/StepperContext';
import { Step } from './Step';

interface StepperProps {
  controller: StepperController;
  loading: boolean;
  children: React.ReactElement<StepProps>[] | React.ReactElement<StepProps>;
}

export const Stepper = (props: StepperProps) => {
  const [activeStep, setActiveStep] = useState(0);

  return (
    <StyledStepper activeStep={activeStep} orientation='vertical'>
      <StepperContext.Provider
        value={{
          activeStep,
          setActiveStep,
          controller: props.controller,
          childrenCount: React.Children.count(props.children)
        }}
      >
        {React.Children.map(props.children, (child: React.ReactElement<StepProps>, index) => (
          <Step index={index} loading={props.loading} {...child.props}>
            {child.props.children}
          </Step>
        ))}
      </StepperContext.Provider>
    </StyledStepper>
  );
};
