import React, { Fragment, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '@ui/layouts/header/Header';
import AppMenu from '@ui/layouts/appMenu/AppMenu';
import Footer from '@ui/layouts/footer/Footer';
import useRouting from '@hook/useRouting';
import { Grid, theme } from '@packages/cp-ui';

const Layout = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const routing = useRouting();
  const frameless =
    location.pathname === routing.welcome.getPath() ||
    location.pathname === routing.login.getPath();
  if (frameless) return <Fragment>{children}</Fragment>;
  return (
    <Fragment>
      <Header />
      <Grid
        container
        alignItems={'stretch'}
        flexWrap={'nowrap'}
        sx={{ height: `calc(100% - ${theme.mixins.toolbar.minHeight})`, overflow: 'hidden' }}
      >
        <Grid item sx={{ height: `100%` }}>
          <AppMenu />
        </Grid>
        <Grid item xs sx={{ height: `100%`, overflowY: 'hidden' }}>
          <Grid container flexWrap={'nowrap'} flexDirection={'column'} sx={{ height: `100%` }}>
            <Grid item xs pt={2} px={4} sx={{ height: `100%`, overflowY: 'auto' }}>
              {children}
            </Grid>
            <Grid item>
              <Footer />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Layout;
