import React from 'react';
import { Chip as PdcChip } from '@mzc-pdc/ui';
import { ChipProps } from './infrastructures/ChipProps';

export const Chip = (props: ChipProps) => (
  <PdcChip
    title={props.title}
    color={props.color}
    variant={props.variant}
    size={'small'}
    label={props.label}
    onDelete={props?.onDelete}
    onMouseDown={(event:React.MouseEvent) => {
      if (props.onDelete != null) event.stopPropagation();
    }}
  />
);
