import React from 'react';
import { Button } from '@mzc-pdc/ui';
import { ButtonProps } from './infrastructures/ButtonProps';

export const CommandButton = (props: ButtonProps) => (
  <Button
    variant={props.variant ?? 'contained'}
    color={'primary'}
    size={props.size ?? 'medium'}
    href={props.href}
    startIcon={props.startIcon}
    endIcon={props.endIcon}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    {props.children}
  </Button>
);
